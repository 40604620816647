import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import { jsx, Themed } from "theme-ui"

import BackButton from "../components/backButton"
import Tag from "../components/tag";

import { MDXRenderer } from "gatsby-plugin-mdx"

export default function BlogPost({ data }) {
    const post = data.post
    const { title, date, tags } = post.frontmatter;

    return (
        <Layout>
            <SEO title={title} />
            <BackButton texto="Voltar para os posts"/>
            <div>
                <section sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                }}>
                    <h1>
                        {title}
                    </h1>
                    <h3> {date} </h3>
                </section>
                <section sx={{ display: 'flex' }}>
                    {tags.map((tag) => {
                        return (
                            <Tag nome={tag} key={tag}/>
                        )
                    })}
                </section>
            </div>
            <Themed.hr/>
            <MDXRenderer>
                {post.body}
            </MDXRenderer>
        </Layout>
    )
}

export const blogPostQuery = graphql`
    query($slug: String!) {
        post: mdx(fields: {slug: {eq: $slug}}) {
            body
            frontmatter {
                author
                date(formatString: "DD/MM/YYYY", locale: "PT")
                title
                tags
            }
        }
    }
`