import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/** @jsx jsx */
import { Link as LinkThemed, jsx } from "theme-ui"

const BackButton = ({ url, texto }) => {
    return (
        <h4>
            <LinkThemed as={Link} to={url} variant="third" sx={{
                lineHeight: 1,
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content'
            }}>
                <FontAwesomeIcon icon={faArrowLeft} size="xs" sx={{ marginRight: 2 }}> </FontAwesomeIcon>
                <span>
                    {texto}
                </span>
            </LinkThemed>
        </h4>
    )
}

BackButton.propTypes = {
    texto: PropTypes.string,
    url: PropTypes.string
}

BackButton.defaultProps = {
    texto: "",
    url: "../",
}

export default BackButton